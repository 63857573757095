import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AlertService } from '../../../../shared/Services/alert.service';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class FeeStrctureServiceService {
  schoolID: any;
  feesData: any;
  employeeID: any;
  //updateFees:any;
  updatePenaltyYear: string;
  constructor(private http: HttpClient, private alertService: AlertService) {
    this.schoolID = localStorage.getItem('school_id');
    this.employeeID = localStorage.getItem('employee_id');
  }
  //private feesStrcture = new BehaviorSubject(true);

  private feesStrctureModel = new Subject;
  private feesStrcutrList = new Subject;
  private feesListData = new Subject;
  private academicYear = new Subject;
  private noOfDays = new Subject;
  private peanltyList = new Subject;
  private feesStrctureModel1 = new Subject;

  getFeeStrctureData(academic_id: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolID);
    params = params.append('academic_year', academic_id);
    let Url = `${environment.base_url}fee_structure`;
    return this.http.get(Url, { params: params });
  };

  //get reminder list
  getReminderAlertData(params: any): Observable<any> {
    return this.http.get(environment.base_url + "sms_template/get", { params: params });
  }

  //  setUpdateFeesStrcture(data:any){
  //    this.feesStrcture.next(data);
  //  }
  //  getUpdateFeesStrcture():Observable<any>{
  //    return this.feesStrcture.asObservable();
  //  }
  setListRefresh(academic_id: string) {
    this.feesStrcutrList.next(academic_id);
  }
  getListRefresh(): Observable<any> {
    return this.feesStrcutrList.asObservable();
  }
  //update fees
  update_fees_strcture(data: any, old_fee: any, academicyear: string): Observable<any> {
    console.log(data);
    let startDate = moment(data.fromDate).format('YYYY-MM-DD');
    let updateFees = {
      "school": this.schoolID,
      "academic_year": academicyear,
      "added_by": this.employeeID,
      "primary_key": old_fee.pk,
      "tax": data.taxSlab,
      "fee_type": data.updatefeeType,
      "fee_code": data.updateFeeCode,
      "from_date": startDate,
      "due_days": data.updateDueDays,
      "installment": data.updateDuration,
      "send_payment_alerts": data.send_payment_alerts,
    };
    let url = `${environment.base_url}fee_structure/update/${this.schoolID}/${academicyear}`;
    return this.http.patch(url, updateFees);
  }

  //update status
  update_fees_strcture_status(old_fee: any, academicyear: string): Observable<any> {

    let updateFees = {
      "school": this.schoolID,
      "academic_year": academicyear,
      "added_by": this.employeeID,
      "primary_key": old_fee.pk,
      // "tax": data.taxSlab,
      // "fee_type": data.updatefeeType,
      // "fee_code": data.updateFeeCode,
      // "from_date": startDate,
      // "due_days": data.updateDueDays,
      // "installment": data.updateDuration,
      "status": !old_fee.status
    };
    let url = `${environment.base_url}fee_structure/update/${this.schoolID}/${academicyear}`;
    return this.http.patch(url, updateFees);
  };
  //delete fees
  fees_type_delete(old_fee: any, academicyear: string): Observable<any> {
    let url = `${environment.base_url}fee_structure/delete/${this.schoolID}/${academicyear}/${old_fee.pk}`;
    return this.http.delete(url);
  }
  setCloseModel(academic_id: string) {
    this.feesStrctureModel.next(academic_id);
  }
  getCloseModel(): Observable<any> {
    return this.feesStrctureModel.asObservable();
  }
  setAddFeeModel() {
    //this.feesStrctureModel.next();
  }
  getAddFeeModel(): Observable<any> {
    return this.feesStrctureModel1.asObservable();
  }
  //get tax slabs
  get_tax_slab(): Observable<any> {
    const formData = new FormData();
    formData.append("school_id", this.schoolID);
    let params = new HttpParams();
    params = params.append('school', this.schoolID);
    let url = `${environment.base_url}fees_tax_slab`;
    return this.http.get(url, { params: params });
  }
  //create tax slabs
  add_tax_slab(data: any): Observable<any> {
    let tax = {
      "school": this.schoolID,
      "added_by": this.employeeID,
      "tax_percent": data.taxPercent,
      "status": data.taxStatus
    };
    let url = `${environment.base_url}fees_tax_slab/create`;
    return this.http.post(url, [tax]);
  }
  //udate tax slabs
  update_tax_slab(data: any, oldData: any): Observable<any> {
    console.log(oldData);
    console.log(data);

    let tax = {
      "school": this.schoolID,
      "added_by": this.employeeID,
      "tax_percent": data.taxPercent,
      "status": data.taxStatus
    };
    let url = `${environment.base_url}fees_tax_slab/update/${this.schoolID}/${oldData.pk}`;
    return this.http.patch(url, tax);
  }
  //delete tax slab
  delete_tax_slab(tax: any): Observable<any> {
    let url = `${environment.base_url}fees_tax_slab/delete/${this.schoolID}/${tax.pk}`;
    return this.http.delete(url);
  }

  //create fee structure
  create_fee_strcture(payload: any): Observable<any> {
    return this.http.post(environment.base_url + "fee_structure/create", payload);
  }

  //add fees for a class
  add_fees_toClass(payload: any): Observable<any> {
    return this.http.post(environment.base_url + "classes_fee_details/create", payload);
  }

  //get fee list
  get_fee_list_data(params: any): Observable<any> {
    return this.http.get(environment.base_url + "classes_fee_details", { params: params });
  }

  //delete class fees
  delete_class_fees(data: any, year_id: string): Observable<any> {
    console.log(data);

    let params = new HttpParams();
    params = params.append('school', this.schoolID);
    params = params.append('academic_year', data.year_id);
    params = params.append('classes', data.class_id);
    let deleteClass = {
      "school": this.schoolID,
      "academic_year": year_id,
      "added_by": this.employeeID,
      "fee_structure": data.fee_structure.pk
    }
    let httpOptions: any = {
      body: deleteClass
    }
    let url = `${environment.base_url}classes_fee_details/delete/${this.schoolID}/${data.pk}`;
    return this.http.delete(url, httpOptions);
  }

  setFeesListData(year: any) {
    this.feesListData.next(year);
  }

  getFeesListData(): Observable<any> {
    return this.feesListData.asObservable();
  }

  setAcademicYear(year: any) {
    this.academicYear.next(year);
  }

  getAcademicYear(): Observable<any> {
    return this.academicYear.asObservable();
  }

  update_fee_amount(data: any, oldData: any): Observable<any> {
    let updateFees = {
      "school": this.schoolID,
      "academic_year": oldData.academic_year.year_id,
      "classes": oldData.feesValue.classes.pk,
      "added_by": this.employeeID,
      "fee_structure": oldData.feesValue.fee_structure.pk,
      "primary_key": oldData.feesValue.pk,
      "fee_amount": data.newAmount,
      //"tax_amount": 10,
      "tax_included": data.newTaxStatus? data.newTaxStatus : false,
      //"total_fee": 160.5,
      //"status": true
    }
    let url = `${environment.base_url}classes_fee_details/update/${this.schoolID}/${oldData.feesValue.pk}`;
    return this.http.patch(url, updateFees);
  }
  //update status
  update_fee_status(oldData: any): Observable<any> {
    console.log(oldData);
    let updateFees = {
      "school": this.schoolID,
      "academic_year": oldData.academic_year.year_id,
      "classes": oldData.feesValue.classes.pk,
      "added_by": this.employeeID,
      "fee_structure": oldData.feesValue.fee_structure.pk,
      "primary_key": oldData.feesValue.pk,
      "status": !oldData.feesValue.status,
      "fee_amount": oldData.feesValue.fee_amount
    }
    let url = `${environment.base_url}classes_fee_details/update/${this.schoolID}/${oldData.feesValue.pk}`;
    return this.http.patch(url, updateFees);
  }

  //add penality fees
  add_penalty_fees(payload: any): Observable<any> {
    const formData = new FormData();
    for (const key in payload) {
      formData.set(key, payload[key]);
    }
    return this.http.post(environment.base_url + "fee_penalty/create", formData);
  }

  setpenaltyData(data: any) {
    this.noOfDays.next(data);
  }
  getpenaltyData(): Observable<any> {
    return this.noOfDays.asObservable();
  }

  //get fees penality
  get_fees_penalty(params: any): Observable<any> {
    return this.http.get(environment.base_url + "fee_penalty/list", { params: params });
  }

  //set penality filter
  setPenaltyFilter(data: any) {
    this.peanltyList.next(data);
  }

  //get penality filter
  getPenaltyFilter(): Observable<any> {
    return this.peanltyList.asObservable();
  }

  //update fee penality
  update_fees_penalty(payload: any): Observable<any> {
    const formData = new FormData();
    for (const key in payload) {
      formData.set(key, payload[key]);
    }
    return this.http.patch(environment.base_url + "fee_penalty/update", formData);
  }
}
