import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
@Injectable({
	providedIn: 'root',
})
export class ClassTeacherService {
	schoolId: any;
	employeeID: any;
	invokeFirstComponentFunction = new EventEmitter();
	subsVar: Subscription;
	onFirstComponentButtonClick(pk: string) {
		this.invokeFirstComponentFunction.emit(pk);
	}
	isClassTeacherAdded = new Subject();
	constructor(private http: HttpClient) {
		this.schoolId = localStorage.getItem('school_id');
		this.employeeID = localStorage.getItem('employee_id');
	}
	addClassTeacher(data: any): Observable<any> {
		const {
			schoolID: school,
			employeeID: added_by,
			academicID: academic_year,
		} = JSON.parse(localStorage.getItem('academicDataObj'));

		let teacherData = {
			school,
			academic_year,
			added_by,
			classes: data.className,
			section: data.classSection,
			employee: data.classTeacher,
			status: true,
		};
		const urlGet = `${environment.base_url}class_teacher/create`;
		return this.http.post(urlGet, teacherData);
	}
	//get assigned class teachers list
	getAssignClassTeachers(academic_pk: string): Observable<any> {
		let params = new HttpParams();
		params = params.append('school', this.schoolId);
		params = params.append('academic_year', academic_pk);
		const url = `${environment.base_url}class_teacher`;
		return this.http.get(url, { params: params });
	}
	getClassTeacherList(): Observable<any> {
		const url = `${environment.base_url}classes_section/list/${this.schoolId}`;
		return this.http.get(url);
	}
	getSectionClassTeacher(): Observable<any> {
		const sectionList = new FormData();
		sectionList.append('school_id', this.schoolId);
		sectionList.append('divisions', JSON.stringify({ divisions: ['All'] }));
		const urlGet = `${environment.base_url}/school/get_school_classes_sections`;
		return this.http.post(urlGet, sectionList);
	}
	getClassTeacherData(page: any): Observable<any> {
		let params = new HttpParams();
		params = params.append('school', this.schoolId);
		params = params.append('page', page);
		const urlGet = `${environment.base_url}employees`;
		return this.http.get(urlGet, { params: params });
	}
	//delete class teacher
	deleteClassTeacher(data: any): Observable<any> {
		let deleteTeacher = {
			primary_key: data.oldData.pk,
			classes: data.oldData.classes.pk,
		};
		let httpOptions: any = {
			body: deleteTeacher,
		};
		const url = `${environment.base_url}class_teacher/delete/${this.schoolId}`;
		return this.http.delete(url, httpOptions);
	}
	updateClassTeacher(employee: any, oldData: any): Observable<any> {
		let updateData = {
			primary_key: oldData.oldData.pk,
			academic_year: oldData.academic_id,
			added_by: this.employeeID,
			classes: oldData.oldData.classes.pk,
			section: oldData.oldData.section.pk,
			employee,
		};
		const url = `${environment.base_url}class_teacher/update/${this.schoolId}`;
		return this.http.patch(url, updateData);
	}
	updateClassTeacherStatus(oldData: any): Observable<any> {
		let updateData = {
			primary_key: oldData.oldData.pk,
			academic_year: oldData.academic_id,
			added_by: this.employeeID,
			classes: oldData.oldData.classes.pk,
			section: oldData.oldData.section.pk,
			//"employee": data.classTeacher
			status: !oldData.oldData.status,
		};
		const url = `${environment.base_url}class_teacher/update/${this.schoolId}`;
		return this.http.patch(url, updateData);
	}

	setIsClassTeacherAdded(value) {
		this.isClassTeacherAdded.next(value);
	}

	getIsClassTeacherAdded() {
		return this.isClassTeacherAdded;
	}
}
