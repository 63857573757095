import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
@Injectable({
	providedIn: 'root',
})
export class LeaveService {
	schoolId: any;
	employeeID: any;
	constructor(private _http: HttpClient) {
		this.schoolId = localStorage.getItem('school_id');
		this.employeeID = localStorage.getItem('employee_id');
	}
	private empLeave = new Subject();
	//get academic years
	getAcademicYears(): Observable<any> {
		let params = new HttpParams();
		params = params.append('school', this.schoolId);
		const url = `${environment.base_url}academic_sessions`;
		return this._http.get(url, { params });
	}
	get_leave_employee_detasil(page, academic, status: string): Observable<any> {
		let params = new HttpParams();
		params = params.append('school', this.schoolId);
		if (academic) {
			params = params.append('academic_year', academic);
		}
		if (status) {
			params = params.append('leave_status', status);
		}
		params = params.append('page', page);
		const getUrl = `${environment.base_url}employee_leave_record`;
		return this._http.get(getUrl, { params: params });
	}
	get_employee_leave_statement(academic_pk: string): Observable<any> {
		// const formData:FormData= new FormData();
		// formData.append('school',this.schoolId);
		// formData.append("employee",this.employeeID);
		// formData.append("academic_year", academic_pk);
		let params = new HttpParams();
		params = params.append('school', this.schoolId);
		params = params.append('employee', this.employeeID);
		params = params.append('academic_year', academic_pk);
		const getUrl = `${environment.base_url}employee_leave_record`;
		return this._http.get(getUrl, { params: params });
	}
	add_employee_leave(empData: any): Observable<any> {
		console.log(empData);

		let from_date = moment(empData.from_date).format('YYYY-MM-DD');
		let to_date = moment(empData.to_date).format('YYYY-MM-DD');
		let doctorCertificate;
		if (Array.isArray(empData.doctorCer)) {
			doctorCertificate = empData.doctorCer[0];
		} else {
			doctorCertificate = '';
		}
		const academicYear = JSON.parse(localStorage.getItem('academicDataObj')).academicID;
		const formData: FormData = new FormData();
		formData.append('school', this.schoolId);
		formData.append('academic_year', academicYear);
		formData.append('employee', this.employeeID);
		formData.append('leave_type', empData.leave_type);
		formData.append('from_date', from_date);
		formData.append('to_date', to_date);
		formData.append('message', empData.message);
		formData.append('subject', empData.subject);
		formData.append('door_no', empData.doorno);
		formData.append('locality', empData.locality);
		formData.append('landmark', empData.landmark);
		formData.append('pin_code', empData.pincode);
		formData.append('city', empData.city);
		formData.append('street_name', empData.streetname);
		formData.append('state', empData.state);
		formData.append('country', empData.country);
		formData.append('contact_number', empData.contact);
		formData.append('doctor_certificate', doctorCertificate);
		formData.forEach((val, i) => {
			console.log(i, val);
		});
		const getUrl = `${environment.base_url}employee_leave_record`;
		return this._http.post(getUrl, formData);
	}

	setEmployeeLeave(academic_year): any {
		this.empLeave.next(academic_year);
	}
	getEmployeeLeave(): Observable<any> {
		return this.empLeave.asObservable();
	}
	// get_employee_full_detaisl(leaveid:string):Observable<any>{
	//   const formData:FormData= new FormData();
	//   formData.append("school_id",this.schoolId);
	//   formData.append("employee_uuid",this.employeeID);
	//   formData.append("leave_id",leaveid);
	//   const getUrl=`${environment.base_url}/school/get_employee_leave_full_details`
	//   return this._http.post(getUrl,formData);
	// }
	employee_leave_action(status: string, empdata: any, comment: string): Observable<any> {
		console.log(empdata);

		const formData: FormData = new FormData();
		formData.append('school', this.schoolId);
		formData.append('academic_year', empdata?.academic_year);
		formData.append('employee', empdata?.employee?.primary_key);
		formData.append('leave_status', status);
		formData.append('primary_key', empdata?.primary_key);
		formData.append('comment', comment);
		formData.append('approved_by', this.employeeID);
		const getUrl = `${environment.base_url}employee_leave_record`;
		return this._http.patch(getUrl, formData);
	}
	get_image_gallery(params,primary_key: any): Observable<any> {
		const getUrl = `${environment.base_url}gallery_references/list/${primary_key}`;
		return this._http.get(getUrl,{params:params});
	}
	delete_gallery(gallery_id: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('employee_id', this.employeeID);
		formData.append('gallery_id', gallery_id);
		const getUrl = `${environment.base_url}/patashala/delete_school_gallery`;
		return this._http.post(getUrl, formData);
	}
	create_gallery(gallery: any, image: any): Observable<any> {
		console.log(gallery);
		let wgArr = [];
		let imgGlboalArr = gallery.uploadImages;
		const formData: FormData = new FormData();
		formData.append('school', this.schoolId);
		formData.append('added_by', this.employeeID);
		formData.append('gallery', gallery.folder_name);
		//formData.append('title', gallery.title);
		formData.append('gallery_description', gallery.gallery_description);
		//for (let image = 0;image <= imgGlboalArr.length - 1;image++) {
		// wgArr.push('file' + image)
		//   formData.append('file' + image, imgGlboalArr[image])
		formData.append('gallery_image', image);
		//}

		const getUrl = `${environment.base_url}gallery_references/create`;
		return this._http.post(getUrl, formData);
	}
	get_full_gallery(gallery_id: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('gallery_id', gallery_id);
		const getUrl = `${environment.base_url}/patashala/getting_school_full_gallery`;
		return this._http.post(getUrl, formData);
	}
	//delete image
	// deleteGalleryImage(gallery_id: any): Observable<any> {
	// 	const getUrl = `${environment.base_url}gallery_references/delete/${this.schoolId}/${gallery_id}`;
	// 	return this._http.delete(getUrl);
	// }
	deleteGalleryImage(primary_key : any, gallery : any): Observable<any> {
		const getUrl = `${environment.base_url}gallery_references/delete/${gallery}/${primary_key}`;
		// const getUrl = `${environment.base_url}gallery_references/update/${this.schoolId}/${gallery}`;
		return this._http.delete(getUrl);
	}
}
