import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
@Injectable({
	providedIn: 'root',
})
export class AcademicsService {
	schoolId: any;
	employeeID: any;
	subjectData: any;
	classSubjects: any;
	constructor(private http: HttpClient) {
		this.schoolId = localStorage.getItem('school_id');
		this.employeeID = localStorage.getItem('employee_id');
	}
	private refreshAfterAdd = new Subject<any>();
	private teacherData = new Subject();

	//get class section timetable
	getClassSectionTimetable(params: any): Observable<any> {
		return this.http.get<any>(environment.base_url + 'class_timetable', { params: params });
	}

	getClassTimeTable(timeTable: any): Observable<any> {
		console.log(timeTable);
		let params = new HttpParams();
		params = params.append('school', this.schoolId);
		params = params.append('time_table_structure', timeTable);
		const url = `${environment.base_url}time_table`;
		return this.http.get(url, { params: params });
	}

	getClassSectionTimeTable(timingsData: any): Observable<any> {
		this.subjectData = timingsData;
		const formData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('class', timingsData.className);
		formData.append('academic_start_date', timingsData.academicStart);
		formData.append('section', timingsData.section);
		formData.append('session_serial_no', timingsData.session);
		formData.forEach((i, val) => {
			console.log(val, i);
		});
		const url = `${environment.base_url}/patashala/get_class_section_timetable`;
		return this.http.post(url, formData);
	}

	//get subject by section
	getSubjectbySection(params: any): Observable<any> {
		return this.http.get<any>(environment.base_url + 'classes_subjects', { params: params });
	}

	//get school teachers
	getSchoolTeacher(params: any): Observable<any> {
		return this.http.get<any>(environment.base_url + 'school_employee', { params: params });
	}

	getClassSubjects(): Observable<any> {
		const formData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('class', this.subjectData.className);
		formData.append('academic_start_date', this.subjectData.academicStart);
		formData.append('section', this.subjectData.section);
		formData.append('session_serial_no', this.subjectData.session);
		formData.forEach((i, val) => {
			console.log(val, i);
		});
		const url = `${environment.base_url}/school/get_weekly_subject_count`;
		return this.http.post(url, formData);
	}

	getClassTeachersList(subject: any): Observable<any> {
		console.log(this.classSubjects);
		let dayName = this.classSubjects.split('_')[0];
		let startDate = this.classSubjects.split('_')[1].split('-')[0];
		let startDateSet = startDate.substring(startDate.length - 2);
		let strTime = startDate.substring(0, startDate.length - 2);
		let startFullDate = strTime + ' ' + startDateSet;
		let endDate = this.classSubjects.split('-')[1];
		let endDateSet = endDate.substring(startDate.length - 2);
		let endTime = endDate.substring(0, startDate.length - 2);
		let endTimeFull = endTime + ' ' + endDateSet;
		const formData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('class', this.subjectData.className);
		formData.append('section', this.subjectData.section);
		formData.append('subject_name', subject);
		formData.append('period_start_time', startFullDate);
		formData.append('period_end_time', endTimeFull);
		formData.append('day', dayName);
		formData.append('academic_start_date', this.subjectData.academicStart);
		formData.append('session_serial_no', this.subjectData.session);
		formData.forEach((i, val) => {
			console.log(val, i);
		});
		const url = `${environment.base_url}/patashala/get_teacher_class_timetable`;
		return this.http.post(url, formData);
	}

	setClassTableData(timetable: any) {
		console.log(timetable);
		this.classSubjects = timetable;
	}
	// getClassTableData():Observable<any>{
	//   return this.classSubjects.asObservable();
	// }

	addClassAndSectionData(payload: any): Observable<any> {
		return this.http.post<any>(environment.base_url + 'class_timetable', payload);
	}

	updateClassAndSectionData(payload: any): Observable<any> {
		const formData: any = new FormData();
		for (const key in payload) {
			formData.append(key, payload[key]);
		}
		return this.http.patch<any>(environment.base_url + 'class_timetable', formData);
	}

	setRefreshData(Subjectadd): any {
		this.refreshAfterAdd.next(Subjectadd);
	}
	getRefreshData(): Observable<any> {
		return this.refreshAfterAdd.asObservable();
	}
	getViceTeachersList(subject: any): Observable<any> {
		const formData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('subject_name', subject);
		formData.forEach((i, val) => {
			console.log(val, i);
		});
		const url = `${environment.base_url}/school/get_subject_vice_teachers_list`;
		return this.http.post(url, formData);
	}
	getTeacherTimeTable(academic: any, teacher: any): Observable<any> {
		console.log(academic);
		console.log(teacher);

		const formData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('teacher_uuid', teacher.employee_uuid);
		formData.append('academic_start_date', academic.startDate);
		formData.append('session_serial_no', academic.session);
		formData.forEach((i, val) => {
			console.log(val, i);
		});
		const url = `${environment.base_url}/patashala/get_teacher_timetable`;
		return this.http.post(url, formData);
	}
	setTeacherTimeTableView(value: any): any {
		this.teacherData.next(value);
	}
	getTeacherTimeTableView(): Observable<any> {
		return this.teacherData.asObservable();
	}
}
