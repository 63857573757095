import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { AlertService } from '../../../../../shared/Services/alert.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeTableMasterService {
  schoolID: any;
  employeeID: any;
  added_by: string;
  private updatetable = new Subject;
  private newAcacademic = new Subject;
  constructor(private http: HttpClient, private alertService: AlertService) {
    this.schoolID = localStorage.getItem('school_id');
    this.employeeID = localStorage.getItem('employee_id');
    this.added_by = localStorage.getItem('added_by');
  }

  //get timetable structure
  getTimeTableStrcture(parmas: any): Observable<any> {
    return this.http.get(environment.base_url + "time_table_structure", { params: parmas });
  }

  //get timetable Arrange structure
  getTimeTableArrangeStrcture(parmas: any): Observable<any> {
    return this.http.get(environment.base_url + "time_table", { params: parmas });
  }

  //delete time table
  deleteTimeTable(primary_key: string): Observable<any> {
    let Url = `${environment.base_url}time_table_structure/delete/${this.schoolID}/${primary_key}`;
    return this.http.delete(Url);
  }

  //create timetable
  timetable_structure_create(payload: any): Observable<any> {
    return this.http.post(environment.base_url + "time_table_structure/create", payload);
  }

  //get weekly suject
  get_weekly_subject_count(params: any): Observable<any> {
    return this.http.get(environment.base_url + "weekly_subject_count", { params: params });
  }

  //delete weekly subject count
  delete_weekly_subject_count(payload: any): Observable<any> {
    return this.http.delete(environment.base_url + "weekly_subject_count/delete", payload);
  }

  //add weekly subject
  add_weekly_subjects(payload: any): Observable<any> {
    return this.http.post(environment.base_url + "weekly_subject_count/create", payload);
  }

  //update time table
  updateTimeTableStructure(payload: any): Observable<any> {
    return this.http.patch(environment.base_url + "time_table_structure/update", payload);
  }

  setUpdateTimetable(check: object) {
    this.updatetable.next(check);
  }
  getUpdateTimetable(): Observable<any> {
    return this.updatetable.asObservable();
  }

  //arange time table
  arrange_timeTime_new(payload: any): Observable<any> {
    return this.http.post(environment.base_url + "time_table/create", payload);
  }

  //delete time table
  delete_timeTable(time_table_pk: string): Observable<any> {
    let Url = `${environment.base_url}time_table/delete/${this.schoolID}/${time_table_pk}`;
    return this.http.delete(Url);
  }

  add_subjects(data: any): Observable<any> {
    console.log(data);
    let addSubjects = {
      "school": this.schoolID,
      "added_by": this.employeeID,
      "division": data.division,
      "classes": data.className,
      "sections": data.section,
      "subjects": data.subjectName.trim(),
      "subject_code": data.subjectCode.trim(),
      "status": true,
      "subject_type": data.subjectType
    };
    let Url = `${environment.base_url}classes_subjects/create`;
    return this.http.post(Url, addSubjects);
  }
  //update subject name
  update_subjects(data: any, newData: any): Observable<any> {
    let updateSubject = {
      "school": this.schoolID,
      "added_by": this.employeeID,
      "primary_key": data.sub_pk,
      "classes": data.class,
      "section": data.section,
      "subjects": newData.subjectName,
      "subject_code": newData.subject_code,
      "subject_type": newData.subjectType
    }
    let Url = `${environment.base_url}classes_subjects/update`;
    return this.http.patch(Url, updateSubject);
  }
  //delete Subject
  delete_Subject(data: any): Observable<any> {
    let deleteData = {
      "school": this.schoolID,
      "added_by": this.employeeID,
      "primary_key": data.sub_pk,
      "classes": data.class,
      "section": data.section
    }
    const httpOptions: any = {
      body: deleteData
    };
    let Url = `${environment.base_url}classes_subjects/delete`;
    return this.http.delete(Url, httpOptions);
  }
  update_subject_status(oldData: any, data: any, newData: any): Observable<any> {
    console.log(data, newData);
    console.log(oldData);
    let updateStatus = {
      "school": this.schoolID,
      "added_by": this.employeeID,
      "primary_key": oldData.sub_pk,
      "classes": oldData.class,
      "section": oldData.section,
      "status": !oldData.subj_status
    }
    let Url = `${environment.base_url}classes_subjects/update`;
    return this.http.patch(Url, updateStatus);
  }

  addAcademicYearNew(check: object) {
    this.newAcacademic.next(check);
  }
  getAcademicYearNew(): Observable<any> {
    return this.newAcacademic.asObservable();
  }
  addSessions(data: any): Observable<any> {
    console.log(data);
    let sessions = [];
    data.sessions.map((sess: any, i: number) => {
      let startSession = moment(sess.from_date).format('DD-MM-YYYY');
      let endSession = moment(sess.to_date).format('DD-MM-YYYY');
      let type = {
        "session_serial_no": i + 1,
        "session_from_date": startSession,
        "session_to_date": endSession,
        "weekly_working_days": data.workingDays
      };
      sessions.push(type);
    });
    let startDate = moment(data.academicYears.academic_start_date).format('DD-MM-YYYY');
    let endDate = moment(data.academicYears.academic_end_date).format('DD-MM-YYYY');
    console.log(startDate)
    console.log(endDate)
    let academicYear = {
      "school": this.schoolID,
      "added_by": this.employeeID,
      "academic_start_date": startDate,
      "academic_end_date": endDate,
      "sessions": sessions
    };
    let Url = `${environment.base_url}academic_session/create`;
    return this.http.post(Url, academicYear);
  }
  update_school_sesions(newData: any, academic_year: string, session_id: string, oldData: any): Observable<any> {
    //  console.log(oldData,newData);
    let sessionStart;
    let sessionEnd;
    console.log(newData);
    console.log(academic_year);
    console.log(session_id);
    console.log(oldData);
    let selectStartDate = moment(newData.sessStartDate).format('DD-MM-YYYY');
    let selectEndDate = moment(newData.sessEndDate).format('DD-MM-YYYY');

    let data = {
      "added_by": this.employeeID,
      // "academic_start_date": "06-06-2025",
      // "academic_end_date": "02-06-2026",
      "sessions":
      {
        "pk": session_id,
        //"session_serial_no": 1,
        "session_from_date": selectStartDate,
        "session_to_date": selectEndDate,
        //"weekly_working_days": 4
      }
    };

    let Url = `${environment.base_url}academic_session/update/${this.schoolID}/${academic_year}/${session_id}`;
    return this.http.patch(Url, data);
  }
  delete_school_sesions(newData: any, academicYear: string, session_id: any): Observable<any> {
    let Url = `${environment.base_url}academic_session/delete/${this.schoolID}/${academicYear}/${session_id}`;
    return this.http.delete(Url);
  }
  add_divisions(divisions: any): Observable<any> {
    console.log(divisions);
    let data = {
      "added_by": this.added_by,
      "division_name": divisions,
      "status": true,
      "school": this.schoolID
    }
    let Url = `${environment.base_url}division/create`;
    return this.http.post(Url, data);
  }
  update_divisions(divisions: any): Observable<any> {

    let data = {
      "added_by": this.added_by,
      "status": divisions.status,
    }
    let Url = environment.base_url + `division/update/${this.schoolID}/${divisions.primary_key}`
    return this.http.patch(Url, data);
  }
  update_division_name(newData: any, old_data: any): Observable<any> {
    console.log(old_data);
    let update_division = {
      "added_by": this.employeeID,
      "division_name": newData.new_division_name,
      "order_number": newData.order_number,
    };
    let Url = environment.base_url + `division/update/${this.schoolID}/${old_data.primary_key}`
    return this.http.patch(Url, update_division);
  }
  delete_division_name(division: any): Observable<any> {
    console.log(division);
    let Url = environment.base_url + `division/delete/${this.schoolID}/${division.primary_key}`
    return this.http.delete(Url);
  }
}
