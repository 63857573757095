import { AccountsState } from "./account.types";
import { AccountActions, ACCOUNTS_INBOX_FILTERS_DATA, ACCOUNTS_INBOX_FILTERS_DOWNLOAD_RESPONSE, ACCOUNTS_OVERVIEW_FILTERS_DATA, DAY_WISE_FEES_REPORT_DATA, STUDENT_SELETED_FROM_INBOX } from "./accounts.actions";


export const initialAccountsState: AccountsState = {
    accountsInboxData:null,
	fees_download_pdf_excel:null,
	day_wise_fees_report:null,
	accountsOverview:null,
	seletedStudentFees:{},
};

export const accountReducer = (
	state: AccountsState = initialAccountsState,
	action: AccountActions
): AccountsState => {
	switch (action.type) {
		case ACCOUNTS_INBOX_FILTERS_DATA:
			return { ...state, accountsInboxData: action.payload };
		case ACCOUNTS_INBOX_FILTERS_DOWNLOAD_RESPONSE:
			return { ...state, fees_download_pdf_excel: action.payload };
		case DAY_WISE_FEES_REPORT_DATA:
			return { ...state, day_wise_fees_report: action.payload };
		case ACCOUNTS_OVERVIEW_FILTERS_DATA:
			return { ...state, accountsOverview: action.payload };
		case STUDENT_SELETED_FROM_INBOX:
			return { ...state, seletedStudentFees: action.payload };

		default:
			return state;
	}
};
