export const  smsTypesStatic = [
  {
    key:'Reminder',
    value:'Reminder'
  },
  {
    key:'Alert',
    value:'Alert'
  },
  {
    key:'Attendance',
    value:'Attendance'
  },
  {
    key:'Holiday',
    value:'Holiday'
  },
  {
    key:'Exams',
    value:'Exams'
  },
  {
    key:'Fees',
    value:'Fees'
  },
  {
    key:'Events',
    value:'Events'
  },
  {
    key:'Text Message',
    value:'Text Message'
  }
]
