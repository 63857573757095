import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GalleryBarrierService {
  school_id:any;
  employee_id:any;
  constructor(private http:HttpClient) { 
    this.school_id = localStorage.getItem('school_id');
    this.employee_id = localStorage.getItem('employee_id');
  }

  getGalleryTypes(params:any): Observable<any> {
    const url = environment.base_url+`gallery/list/${this.school_id}`;
    return this.http.get(url,{params:params});
  }
//create gallery barries
  addGalleryBarriers(types: any): Observable<any> {
    console.log(types)
    let formData = new FormData();
    formData.append('school',this.school_id);
    formData.append('added_by', this.employee_id);
    formData.append('title', types.title);
    formData.append('description', types.description);
    let Url = `${environment.base_url}gallery/create`;
    return this.http.post(Url, formData);
  }

  updateGalleryTypes(data: any): Observable<any> {
    let formData = new FormData();
    formData.append('added_by', this.employee_id);
    formData.append('title', data.title);
    formData.append('description', data.description);
    let Url = `${environment.base_url}gallery/update/${this.school_id}/${data.primary_key}`;
    return this.http.patch(Url,formData);
  }
  //delete event
  deleteGalleryBarrier(data: any): Observable<any> {
    let Url = `${environment.base_url}gallery/delete/${this.school_id}/${data.primary_key}`;
    return this.http.delete(Url);
  }

}
