import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	mergeMap,
	switchMap,
	withLatestFrom,
} from 'rxjs/operators';

import { ApiService } from 'src/app/shared/Services/api.service';

import { from } from 'rxjs';
import { HostelAllocateService } from 'src/app/admin/shared/services/hostel-allocate.service';
import { removeKeyWhere, removeUnusedProperty } from 'src/app/helpers/utils';
import { alertAction } from 'src/app/store/alert/alert.actions';
import { Store } from '@ngrx/store';
import { accountActions, AccountActions, ACCOUNTS_INBOX_FILTERS, ACCOUNTS_INBOX_FILTERS_DOWNLOAD_CALL,
	 ACCOUNTS_OVERVIEW_FILTERS, DAY_WISE_FEES_REPORT } from './accounts.actions';
import { BaseResponse } from 'src/app/admin/nav/store/store.types';

const arrayToReject = ['staff_employee', 'student'];
const removeUnusedFields = removeKeyWhere(
	(key, value) => (value === undefined || value === null || value === '') && !arrayToReject.includes(key)
);

@Injectable()
export class AccountEffects {
	constructor(
		private action$: Actions<AccountActions>,
		private apiService: ApiService,
		private store$: Store
	) {}



	@Effect()
	feesList$ = this.action$.pipe(
		ofType(ACCOUNTS_INBOX_FILTERS),
        map((action) => removeUnusedFields(action.payload)),
		mergeMap((action) => {
            const {schoolID:school,academicID:academic_year,} = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
                school,
                academic_year,
				...action
			};
			return this.apiService.doGet<BaseResponse>('student_fee_inbox', params).pipe(
				map((data) => accountActions.account_inbox_filters_data(data)),
				catchError((error) => from([accountActions.account_inbox_filters_data(error)]))
			);
		})
	);

	@Effect()
	downloadFeesData$ = this.action$.pipe(
		ofType(ACCOUNTS_INBOX_FILTERS_DOWNLOAD_CALL),
        map((action) => removeUnusedFields(action.payload)),
		mergeMap((action) => {
            const {schoolID:school,academicID:academic_year,} = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
                school,
                academic_year,
				...action
			};
			return this.apiService.doGet<BaseResponse>('student_fee_report_export', params).pipe(
				map((data) => accountActions.account_inbox_filters_download_response(data)),
				catchError((error) => from([accountActions.account_inbox_filters_download_response(error)]))
			);
		})
	);

	@Effect()
	daywisefeesReport$ = this.action$.pipe(
		ofType(DAY_WISE_FEES_REPORT),
        map((action) => removeUnusedFields(action.payload)),
		mergeMap((action) => {
            const {schoolID:school,academicID:academic_year} = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
                school,
                academic_year,
				...action
			};
			return this.apiService.doGet<BaseResponse>('student_fee_payment/get_day_report', params).pipe(
				map((data) => accountActions.day_wise_fees_reports_api_data(data)),
				catchError((error) => from([accountActions.day_wise_fees_reports_api_data(error)]))
			);
		})
	);

	@Effect()
	feesListOverview$ = this.action$.pipe(
		ofType(ACCOUNTS_OVERVIEW_FILTERS),
        map((action) => removeUnusedFields(action.payload)),
		mergeMap((action) => {
            const {schoolID:school,academicID:academic_year,} = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
                school,
                academic_year,
				...action
			};
			return this.apiService.doGet<BaseResponse>('school_fees_collection_overview', params).pipe(
				map((data) => accountActions.account_overview_filters_data(data)),
				catchError((error) => from([accountActions.account_overview_filters_data(error)]))
			);
		})
	);

}


