import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	schoolId: any;
	employeeID: any;
	constructor(private _http: HttpClient) {
		this.schoolId = localStorage.getItem('school_id');
		this.employeeID = localStorage.getItem('employee_id');
	}

	getDepartmentsList(): Observable<any> {
		let httpParams = new HttpParams();
		httpParams = httpParams.append('school', this.schoolId);
		const getUrl = environment.base_url + `departments`;
		return this._http.get(getUrl, { params: httpParams });
	}
	get_leave_barrier_list(page: any): Observable<any> {
		//const url=`${environment.base_url}departments_leave_barrier/list${this.schoolId}`;
		const url = `${environment.base_url}school_leave_barriers?school=${this.schoolId}&page=${page}`;
		return this._http.get(url);
	}

	add_leave_barriers(leaveData: any): Observable<any> {
		console.log(leaveData);
		let add_leaves = [];
		leaveData.map((val) => {
			add_leaves.push({
				school: this.schoolId,
				added_by: this.employeeID,
				leave_name: val.name,
				status: val.status,
			});
		});
		const url = `${environment.base_url}school_leave_barriers/create`;
		return this._http.post(url, add_leaves);
	}
	update_leave_barriers(leaveData: any): Observable<any> {
		console.log(leaveData);

		let leaves = {
			added_by: this.employeeID,
			status: leaveData.status,
		};
		const url = `${environment.base_url}school_leave_barriers/update/${this.schoolId}/${leaveData.primary_key}`;
		return this._http.patch(url, leaves);
	}
	update_leave_name(new_name: string, old_name: any): Observable<any> {
		console.log(old_name);

		let leaves = {
			added_by: this.employeeID,
			leave_name: new_name,
		};
		const url = `${environment.base_url}school_leave_barriers/update/${this.schoolId}/${old_name.primary_key}`;
		return this._http.patch(url, leaves);
	}
	//delete leave
	delete_leave_name(leave_info: any): Observable<any> {
		const url = `${environment.base_url}school_leave_barriers/delete/${this.schoolId}/${leave_info.primary_key}`;
		return this._http.delete(url);
	}
	//get leave
	get_school_leave_barrier_list(): Observable<any> {
		let httpParams = new HttpParams();
		httpParams = httpParams.append('school', this.schoolId);
		// const url = `${environment.base_url}departments_leave_barrier`;
		const url = `${environment.base_url}school_leave_barriers`;
		return this._http.get(url, { params: httpParams });
	}
	get_school_leave_barrier_data(dep: string): Observable<any> {
		let httpParams = new HttpParams();
		httpParams = httpParams.append('school', this.schoolId);
		httpParams = httpParams.append('page', '1');
		httpParams = httpParams.append('leave_type', dep);
		const url = `${environment.base_url}departments_leave_barrier`;
		return this._http.get(url, { params: httpParams });
	}
	update_leave_barrier(newData: any, oldData: any, type: string): Observable<any> {
		let data: any;
		console.log(data);

		if (type == 'edit') {
			data = {
				leave_code: newData.leave_code,
				leaves_per_year: newData.leavesper_year,
				notice_period: newData.notice_period,
				availability: newData.avail_leaves,
				//"status": true,
				//"department": oldData.department.pk,
				added_by: this.employeeID,
			};
		} else if (type == 'status') {
			data = {
				status: !oldData.status,
			};
		}

		const url = `${environment.base_url}departments_leave_barrier/update/${this.schoolId}/${oldData.primary_key}/${oldData.leave_type.pk}`;
		return this._http.patch(url, data);
	}

	add_leave_barrier_details(newData: any): Observable<any> {
		console.log(newData);
		let leave_barriers = {
			leave_code: newData.leave_code,
			leaves_per_year: newData.leave_years,
			notice_period: newData.leave_noticeperiod,
			availability: newData.leave_avail,
			status: true,
			school: this.schoolId,
			department: newData.department,
			added_by: this.employeeID,
			leave_type: newData.leave_id,
		};
		const url = `${environment.base_url}departments_leave_barrier/create`;
		return this._http.post(url, [leave_barriers]);
	}

	// maker checker services
	getSelectedModules(params: any): Observable<any> {
		const url = `${environment.base_url}school_modules_list`;
		return this._http.get(url, { params: params });
	}
	getMakerChecker(): Observable<any> {
		let formData = new FormData();
		formData.append('school_id', this.schoolId);
		const url = `${environment.base_url}/school/get_school_maker_and_checker`;
		return this._http.post(url, formData);
	}
	get_school_roles(): Observable<any> {
		let getEmpRoles = new FormData();
		getEmpRoles.append('school_id', this.schoolId);
		getEmpRoles.append('wings_name', JSON.stringify({ wings: ['All'] }));
		getEmpRoles.append('departments_name', JSON.stringify({ departments: ['All'] }));
		getEmpRoles.forEach((i, val) => {
			console.log(val, i);
		});
		const url = `${environment.base_url}/school/get_school_roles`;
		return this._http.post(url, getEmpRoles);
	}
	get_roles(): Observable<any> {
		const url = `${environment.base_url}roles`;
		return this._http.get(url);
	};
	get_Permission_Modules(): Observable<any> {
		const url = `${environment.base_url}get_all_available_access/get`;
		return this._http.get(url);
	}

	addMakerChecker(newData: any): Observable<any> {
		console.log(newData);
		const formData: FormData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('module_name', newData.moduleName);
		formData.append('added_employeeid', this.employeeID);
		formData.append('makers', JSON.stringify(newData.makersArray));
		formData.append('checkers', JSON.stringify(newData.checkersArray));
		formData.forEach((i, val) => {
			console.log(val, i);
		});

		const url = `${environment.base_url}/school/add_school_maker_and_checker`;
		return this._http.post(url, formData);
	}

	deleteMakerCheckerData(module: any): Observable<any> {
		let formData = new FormData();
		formData.append('school_id', this.schoolId);
		formData.append('module_name', module);
		formData.append('deleting_employeeid', this.employeeID);
		const url = `${environment.base_url}/school/delete_school_maker_and_checker`;
		return this._http.post(url, formData);
	}

	//PERMISSION MODULE
	create_permissions_module(data): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const url = `${environment.base_url}permission/post`;
		return this._http.post(url, formData);
	}	
	get_permissions_module(params): Observable<any> {
		const url = `${environment.base_url}permission/get`;
		return this._http.get(url, {params:params});
	}
}
