import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { AlertService } from '../../../../../shared/Services/alert.service';
import { ISendHallticket } from 'src/app/admin/views/school-activities/store/examination.types';

@Injectable({
	providedIn: 'root',
})
export class ExaminationService {
	schoolID: any;
	employeeID: any;
	private editExamTypeModel = new Subject();
	private listData = new Subject();

	constructor(private http: HttpClient, private alertService: AlertService) {
		this.schoolID = localStorage.getItem('school_id');
		this.employeeID = localStorage.getItem('employee_id');
	}
	setCloseModel() {
		this.editExamTypeModel.next();
	}
	getCloseModel(): Observable<any> {
		return this.editExamTypeModel.asObservable();
	}

	setRefreshBarrierListData() {
		this.listData.next();
	}
	getRefreshBarrierListData(): Observable<any> {
		return this.listData.asObservable();
	}

	getExamType(params: any): Observable<any> {
		let Url = `${environment.base_url}school_exam_type`;
		return this.http.get(Url, { params: params });
	}

	saveExamType(types: any): Observable<any> {
		let exams = {
			school: this.schoolID,
			added_by: this.employeeID,
			exam_type: types.exam_type,
			status: types.status,
		};

		let Url = `${environment.base_url}school_exam_type/create`;
		return this.http.post(Url, exams);
	}

	updateExamTypeStatus(types: any): Observable<any> {
		let exams = {
			status: types.status,
		};
		let Url = `${environment.base_url}school_exam_type/update/${this.schoolID}/${types.primary_key}`;
		return this.http.patch(Url, exams);
	}

	updateExamTypeName(data: any): Observable<any> {
		let exam = {
			exam_type: data.newname,
		};
		let Url = `${environment.base_url}school_exam_type/update/${this.schoolID}/${data.primary_key}`;
		return this.http.patch(Url, exam);
	}

	getExamSubjects(params: any): Observable<any> {
		let Url = `${environment.base_url}exam_type_subjects`;
		return this.http.get(Url, {params:params});
	}
	addExamSubjects(data: any): Observable<any> {
		let Url = `${environment.base_url}exam_type_subjects`;
		return this.http.post(Url, data);
	}
	removeExamSubjects(payload: any): Observable<any> {
		let formData = new FormData();
		for (const key in payload) {
			formData.append(key, payload[key]);
		}
		let httpOptions: any = {
			body: formData
		  };
		let Url = `${environment.base_url}exam_type_subjects`;
		return this.http.delete(Url, httpOptions);
	}

	getExamBarrier(data: any): Observable<any> {
		// const formData = new FormData();
		// formData.append('school_id', this.schoolID);
		// formData.append('division', data.division);
		// formData.append('class', data.class);
		// formData.append('exam_type', data.exam_type);
		let params = new HttpParams();
		params = params.append('school', this.schoolID);
		params = params.append('classes', data.class);
		if (data.examtype) {
			params = params.append('exam_name', data.examtype);
		}
		let Url = `${environment.base_url}exam_barrier`;
		return this.http.get(Url, { params: params });
	}

	addExamBarrier(data: any): Observable<any> {

		let createExamBarrier = {
			school: this.schoolID,
			exam_name: data.examtype,
			added_by: this.employeeID,
			classes: data.class,
			subject: data.subject,
			max_marks: data.maxmarks,
			min_marks: data.minmarks,
			exam_duration: data.duration,
			status: true,
		};
		let Url = `${environment.base_url}exam_barrier/create`;
		return this.http.post(Url, createExamBarrier);
	}

	getSubjectName(class_id: any): Observable<any> {
		let params = new HttpParams();
		params = params.append('school', this.schoolID);
		params = params.append('class_primary_key', class_id);
		const urlGet = `${environment.base_url}classes_subjects`;
		return this.http.get(urlGet, { params: params });
	}

	updateExamBarrier(old_data: any, data: any): Observable<any> {
		console.log(old_data);

		let updateExamBarrier = {
			school: this.schoolID,
			exam_name: old_data.exam_name.primary_key,
			primary_key: old_data.pk,
			added_by: this.employeeID,
			classes: old_data.classes.pk,
			subject: old_data.subject.primary_key,
			max_marks: data.newmaxmarks,
			min_marks: data.newminmarks,
			exam_duration: data.examduration,
		};
		let Url = `${environment.base_url}exam_barrier/update`;
		return this.http.patch(Url, updateExamBarrier);
	}

	updateExamBarrierStatus(old_data: any): Observable<any> {
		let updateExamBarrier = {
			school: this.schoolID,
			exam_name: old_data.exam_name.primary_key,
			primary_key: old_data.pk,
			added_by: this.employeeID,
			classes: old_data.classes.pk,
			status: !old_data.status,
		};
		let Url = `${environment.base_url}exam_barrier/update`;
		return this.http.patch(Url, updateExamBarrier);
	}
	//delete exam barrier
	delete_exam_barrier(data: any): Observable<any> {
		let Url = environment.base_url + `exam_barrier/delete/${this.schoolID}/${data.pk}`;
		return this.http.delete(Url);
	}
	delete_exam_name(data: any): Observable<any> {
		let Url = environment.base_url + `school_exam_type/delete/${this.schoolID}/${data.primary_key}`;
		return this.http.delete(Url);
	}
	generateExamHalltickets(hallticketData: ISendHallticket): Observable<any> {
    let params = new HttpParams();
    for (const key in hallticketData) {
      params = params.append(key,hallticketData[key]);
    }
    let Url = environment.base_url + `generate_exams_hall_tickets`;
		return this.http.get(Url,{params:params});
	}
}
