import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILogin } from '../../Model/login';

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(private http: HttpClient) {}

	login(data: ILogin): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('mobile_number', data.mobile_number);
		formData.append('password', data.password);
		const url = `${environment.base_url}school_login/login`;
		return this.http.post(url, formData);
	}

	otp(data: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('mobile_number', data);
		const url = `${environment.base_url}school_get_mobile_otp`;
		return this.http.post(url, formData);
	}
	selectSchool(schoolID: any): Observable<any> {
		let schoolObject = {
			data: {
				Academic: {
					Science: ['Incharge', 'Teacher', 'Assistant Teacher', 'Substitute Teacher', 'Lab Assistant'],
					Mathematics: ['Senior Teacher', 'Teacher', 'Junior Teacher', 'Substitute Teacher', 'Incharge'],
					'Computer Science': [
						'Junior Teacher',
						'Assistant Teacher',
						'Substitute Teacher',
						'Lab Assistant',
						'Senior Teacher',
						'Incharge',
					],
				},
				Administration: {
					Accounts: ['Account Manager', 'Junior Accountant', 'Senior Accountant', 'Accoutant', 'Cashier'],
					'Front Office': [
						'Junior Clerk',
						'Senior Clerk',
						'Telecaller',
						'Parent Support',
						'Office Admin',
						'Incharge',
						'Executive',
						'Receptionist',
						'Super Receptionist',
						'Clerk',
					],
					Transportation: [
						'Driver',
						'Helper',
						'Transport Incharge',
						'Transport Manager',
						'Technician',
						'Substitute Driver',
					],
					HR: ['HR', 'HR Manager'],
					Security: ['Security Guard', 'Security Officer', 'Watchman'],
					IT: ['Technician', 'System Admin', 'IT Manager'],
					Maintenance: ['Electrician', 'Plumber', 'Sweeper', 'Scavenger', 'Office Boy', 'Peon', 'Gardian'],
				},
				Management: {
					Management: [
						'Principal',
						'Chairman',
						'Director',
						'Finance Director',
						'Academic Director',
						'Correspondent',
						'Additional Director',
						'Executive Director',
						'HM',
						'Vice Principal',
					],
				},
			},
		};
		const formData: FormData = new FormData();
		formData.append('school_id', schoolID);
		formData.append('data_object', JSON.stringify(schoolObject));
		const url = `${environment.base_url}adding_school_wings_department_roles`;
		return this.http.post(url, formData);
	}
	set_barriers_emloyee(ID: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('school', ID.school_id);
		formData.append('mobile_number', ID.person_mobile_number);
		formData.append('person_name', ID.person_name);
		formData.append('person_email', ID.person_email);
		const url = `${environment.base_url}school_initial_barrier_setup`;
		return this.http.post(url, formData);
	}
	verifyOTP(mob_num, otp): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('mobile_number', mob_num);
		formData.append('otp_password', otp);
		const url = `${environment.base_url}school/school_login_with_otp`;
		return this.http.post(url, formData);
	}
}
