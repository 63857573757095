import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable,Subject } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core'; 
import { Subscription } from 'rxjs/internal/Subscription'; 

@Injectable({
    providedIn: 'root'
})

export class HousesService {
    schoolId: any;
    employeeID:any;
    changeMentor = new BehaviorSubject(null);
    selectMentorEvent = new BehaviorSubject(null);
    selectMentorShow = new BehaviorSubject(null);
    changeCaptain = new BehaviorSubject(null);
    selectedTagValue = new BehaviorSubject(null);
    changeViceCaptain = new BehaviorSubject(null);
    masterHouse = new Subject;
    invokeUpdateComponentFunction = new EventEmitter();    
    subsVar: Subscription;    
    onSecondComponentUpdateButtonClick() {    
        this.invokeUpdateComponentFunction.emit();    
    }


    constructor (private http:HttpClient){
        this.schoolId = localStorage.getItem('school_id');
        this.employeeID = localStorage.getItem('employee_id');
    }

    getHouses():Observable<any>{
        const getHousesList = new FormData();
        getHousesList.append('school_id',this.schoolId);
        const getUrl=`${environment.base_url}/school/get_school_houses`
        return this.http.post(getUrl,getHousesList);
    }

    getWingsList():Observable<any>{
        let formData=new FormData();
        formData.append('school_id',this.schoolId);
        const url = environment.base_url+`wings/list/${this.schoolId}`;
        return this.http.get(url);
    }

    getDepartmentsList(wings):Observable<any>{
        let httpParams = new HttpParams();
        httpParams = httpParams.append('school',this.schoolId);
        httpParams = httpParams.append('wing',wings);
        const getUrl = environment.base_url+`departments`
        return this.http.get(getUrl,{params:httpParams}); 
    }
    //
    getDepartmentsLists():Observable<any>{
        let httpParams = new HttpParams();
        httpParams = httpParams.append('school',this.schoolId);
        const getUrl = environment.base_url+`departments`
        return this.http.get(getUrl,{params:httpParams}); 
    }

    getRolesList(wings:string, departs:string):Observable<any>{
        let httpParams = new HttpParams();
        httpParams = httpParams.append('school',this.schoolId);
        httpParams = httpParams.append('wing',wings);
        httpParams = httpParams.append('department',departs);
        const getUrl = environment.base_url+`roles`
        return this.http.get(getUrl,{params:httpParams}); 
    }
    
    getRolesBasedEmpList(wings, departs, roles):Observable<any>{
        const getEmps = new FormData();
        getEmps.append('school_id',this.schoolId);
        const getUrl =`${environment.base_url}/school/get_school_all_employee_lists`
        return this.http.post(getUrl, getEmps);

    }

    getHouseStdList():Observable<any>{
        const getHseStds = new FormData();
        getHseStds.append('school_id',this.schoolId);
        getHseStds.append('house_uuid', localStorage.getItem("houseUuid"));
        const getUrl =`${environment.base_url}/school/get_house_student_list`
        return this.http.post(getUrl, getHseStds);

    }

    empfun(houseMentor: any): void{
        // console.log(houseMentor);
        this.changeMentor.next(houseMentor);
    }

    getEmpfun(): Observable<any> {
        return this.changeMentor.asObservable();
    }

    setMentor(value): void {
        this.selectMentorEvent.next(value);
    }

    getMentor(): Observable<any> {
        return this.selectMentorEvent.asObservable();
    }

    setMentroModal(value): void {
        console.log(value)
        this.selectMentorShow.next(value);
    }
    getMentorModal(): Observable<any> {
        return this.selectMentorShow.asObservable();
    }

    studentSelctedData(houseCaptains: any): void{
        console.log(houseCaptains);
        this.changeCaptain.next(houseCaptains);
    }
    getStudentfun(): Observable<any> {
        return this.changeCaptain.asObservable();
    }

    studentSelctedDataVicecaptain(houseViceCaptains: any): void{
        console.log(houseViceCaptains);
        this.changeViceCaptain.next(houseViceCaptains);
    }
    getStudentfunVicecaptain(): Observable<any> {
        return this.changeViceCaptain.asObservable();
    }

    setSelectionValue(selectedValue: any): void{
        console.log(selectedValue);
        this.selectedTagValue.next(selectedValue);
    }
    getSelectionValue(): Observable<any> {
        return this.selectedTagValue.asObservable();
    }

    updatingHouseData(data:any):Observable<any>{
        console.log(data);
        // let className=data.className;
        // let sectionName=data.classSection;
        // let teacherUuid=data.classTeacher;
       const updateHouse=new FormData();
       updateHouse.append('school_id',this.schoolId);
       updateHouse.append("added_employeeid",this.employeeID);
       updateHouse.append("house_name", data.houseName);
       updateHouse.append("house_color", data.houseColor);
       updateHouse.append("house_uuid", data.houseId);
       updateHouse.append("house_teacher_mentor_id", data.mentor);
       updateHouse.append("house_captain_id", data.captain);
       updateHouse.append("house_vice_captain_id", data.viceCaptain);
       
       const urlGet=`${environment.base_url}/school/update_houses`
       return this.http.post(urlGet,updateHouse);
     }
    create_house(data:any):Observable<any>{
        const createHouseData = new FormData();
        createHouseData.append("school_id", this.schoolId);
        createHouseData.append("house_name", data.house_name);
        createHouseData.append("house_color", data.house_color);
        createHouseData.append("added_employeeid", this.employeeID);
        const urlGet=`${environment.base_url}/school/create_houses`
        return this.http.post(urlGet,createHouseData);
    }
    setListRefresh(): void{
        this.masterHouse.next();
    }
    getListRefresh(): Observable<any> {
        return this.masterHouse.asObservable();
    }
    
}